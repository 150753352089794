import * as React from "react"
import { Helmet } from "react-helmet";

const description = "Colin Lord is a Front End Developer. He has spent the last decade working primarily in the music and healthcare industries."

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>Colin Lord.com</title>
        <meta
          name="msapplication-config"
          content="/assets/xml/browserconfig.xml"
        />
        <meta name="theme-color" content="#a6192d" />
        <meta name="description" content={description} />
        <link
          href="https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700"
          rel="stylesheet"
        />

        <link href="home.css" rel="stylesheet" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta itemprop="name" content="Colin Lord.com" />
        <meta itemprop="description" content={description} />
        <meta
          itemprop="image"
          content="https://colinlord.com/images/speaking.jpg"
        />

        <meta property="og:url" content="https://colinlord.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Colin Lord.com" />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://colinlord.com/images/speaking.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Colin Lord.com" />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://colinlord.com/images/speaking.jpg"
        />
      </Helmet>

      <div class="wrapper">
        <div class="background"></div>

        <div class="container">
          <div class="about">
            <div class="about__photo">
              <img src="/images/me.jpg" alt="" />
            </div>

            <div class="about__profile">
              <div class="hello">
                <span>Hello!</span>
              </div>
              <h1 class="name">
                <span>I'm</span> Colin Lord
              </h1>
              <h2 class="title">Software Engineering Manager</h2>
              <ul class="list">
                <li>
                  <span class="list__title">Lives In</span>
                  <span class="list__content">Nashville, Tennessee</span>
                </li>
                <li>
                  <span class="list__title">Hometown</span>
                  <span class="list__content">Atlanta, Georgia</span>
                </li>
                <li>
                  <span class="list__title">College</span>
                  <span class="list__content">Florida State University</span>
                </li>
                <li>
                  <span class="list__title">Amateur Radio Call Sign</span>
                  <span class="list__content">KT4ATL</span>
                </li>
              </ul>
            </div>

            <div class="about__social">
              <ul>
                <li>
                  <a href="https://twitter.com/colinlord" title="Twitter">
                    <svg id="svg-twitter" viewBox="0 0 1792 1792">
                      <path d="M1684 408q-67 98-162 167 1 14 1 42 0 130-38 259.5t-115.5 248.5-184.5 210.5-258 146-323 54.5q-271 0-496-145 35 4 78 4 225 0 401-138-105-2-188-64.5t-114-159.5q33 5 61 5 43 0 85-11-112-23-185.5-111.5t-73.5-205.5v-4q68 38 146 41-66-44-105-115t-39-154q0-88 44-163 121 149 294.5 238.5t371.5 99.5q-8-38-8-74 0-134 94.5-228.5t228.5-94.5q140 0 236 102 109-21 205-78-37 115-142 178 93-10 186-50z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://linkedin.com/in/colinlord" title="LinkedIn">
                    <svg id="svg-linkedin" viewBox="0 0 1792 1792">
                      <path d="M477 625v991h-330v-991h330zm21-306q1 73-50.5 122t-135.5 49h-2q-82 0-132-49t-50-122q0-74 51.5-122.5t134.5-48.5 133 48.5 51 122.5zm1166 729v568h-329v-530q0-105-40.5-164.5t-126.5-59.5q-63 0-105.5 34.5t-63.5 85.5q-11 30-11 81v553h-329q2-399 2-647t-1-296l-1-48h329v144h-2q20-32 41-56t56.5-52 87-43.5 114.5-15.5q171 0 275 113.5t104 332.5z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/colinlord" title="Instagram">
                    <svg id="svg-instagram" viewBox="0 0 1792 1792">
                      <path d="M1490 1426v-648h-135q20 63 20 131 0 126-64 232.5t-174 168.5-240 62q-197 0-337-135.5t-140-327.5q0-68 20-131h-141v648q0 26 17.5 43.5t43.5 17.5h1069q25 0 43-17.5t18-43.5zm-284-533q0-124-90.5-211.5t-218.5-87.5q-127 0-217.5 87.5t-90.5 211.5 90.5 211.5 217.5 87.5q128 0 218.5-87.5t90.5-211.5zm284-360v-165q0-28-20-48.5t-49-20.5h-174q-29 0-49 20.5t-20 48.5v165q0 29 20 49t49 20h174q29 0 49-20t20-49zm174-208v1142q0 81-58 139t-139 58h-1142q-81 0-139-58t-58-139v-1142q0-81 58-139t139-58h1142q81 0 139 58t58 139z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://youtube.com/c/colinlordKT4ATL" title="YouTube">
                    <svg
                      id="svg-youtube"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1280 896q0-37-30-54l-512-320q-31-20-65-2-33 18-33 56v640q0 38 33 56 16 8 31 8 20 0 34-10l512-320q30-17 30-54zm512 0q0 96-1 150t-8.5 136.5-22.5 147.5q-16 73-69 123t-124 58q-222 25-671 25t-671-25q-71-8-124.5-58t-69.5-123q-14-65-21.5-147.5t-8.5-136.5-1-150 1-150 8.5-136.5 22.5-147.5q16-73 69-123t124-58q222-25 671-25t671 25q71 8 124.5 58t69.5 123q14 65 21.5 147.5t8.5 136.5 1 150z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://codepen.com/colinlord" title="CodePen">
                    <svg id="svg-codepen" viewBox="0 0 1792 1792">
                      <path d="M216 1169l603 402v-359l-334-223zm-62-144l193-129-193-129v258zm819 546l603-402-269-180-334 223v359zm-77-493l272-182-272-182-272 182zm-411-275l334-223v-359l-603 402zm960 93l193 129v-258zm-138-93l269-180-603-402v359zm485-180v546q0 41-34 64l-819 546q-21 13-43 13t-43-13l-819-546q-34-23-34-64v-546q0-41 34-64l819-546q21-13 43-13t43 13l819 546q34 23 34 64z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://speakerdeck.com/colinlord"
                    title="Speaker Deck"
                  >
                    <svg
                      id="svg-speakerdeck"
                      viewBox="0 0 16 16"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      stroke-linejoin="round"
                      stroke-miterlimit="1.414"
                    >
                      <path d="M1.067 14C.477 14 0 13.525 0 12.94V3.06C0 2.475.478 2 1.067 2h13.866c.588 0 1.065.475 1.065 1.06H16v9.88c0 .585-.478 1.06-1.067 1.06H1.067zm9.957-3.88H4.978c-.387-.007-.7-.32-.7-.707h-.005V6.587h.006c0-.386.31-.7.69-.706h6.04c.38.01.7.32.7.71v2.83h-.01c0 .39-.31.7-.7.71zM7.48 6.934L5.7 8.008l1.766 1.057.013-2.13zm1.04 2.13L10.3 7.99 8.532 6.935l-.01 2.13z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://drupal.org/u/colinlord" title="Drupal">
                    <svg
                      id="svg-drupal"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1295 1586q-5-19-24-5-30 22-87 39t-131 17q-129 0-193-49-5-4-13-4-11 0-26 12-7 6-7.5 16t7.5 20q34 32 87.5 46t102.5 12.5 99-4.5q41-4 84.5-20.5t65-30 28.5-20.5q12-12 7-29zm-39-115q-19-47-39-61-23-15-76-15-47 0-71 10-29 12-78 56-26 24-12 44 9 8 17.5 4.5t31.5-23.5q3-2 10.5-8.5t10.5-8.5 10-7 11.5-7 12.5-5 15-4.5 16.5-2.5 20.5-1q27 0 44.5 7.5t23 14.5 13.5 22q10 17 12.5 20t12.5-1q23-12 14-34zm355-281q0-22-5-44.5t-16.5-45-34-36.5-52.5-14q-33 0-97 41.5t-129 83.5-101 42q-27 1-63.5-19t-76-49-83.5-58-100-49-111-19q-115 1-197 78.5t-84 178.5q-2 112 74 164 29 20 62.5 28.5t103.5 8.5q57 0 132-32.5t134-71 120-70.5 93-31q26 1 65 31.5t71.5 67 68 67.5 55.5 32q35 3 58.5-14t55.5-63q28-41 42.5-101t14.5-106zm53-160q0 164-62 304.5t-166 236-242.5 149.5-290.5 54-293-57.5-247.5-157-170.5-241.5-64-302q0-89 19.5-172.5t49-145.5 70.5-118.5 78.5-94 78.5-69.5 64.5-46.5 42.5-24.5q14-8 51-26.5t54.5-28.5 48-30 60.5-44q36-28 58-72.5t30-125.5q129 155 186 193 44 29 130 68t129 66q21 13 39 25t60.5 46.5 76 70.5 75 95 69 122 47 148.5 19.5 177.5z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://strava.com/athletes/colinlord"
                    title="Strava"
                  >
                    <svg id="svg-strava" viewBox="0 0 16 16">
                      <path d="M10.258 11.963L8.865 9.219H6.822L10.258 16l3.434-6.781h-2.045M6.975 5.486l1.891 3.732h2.781L6.975 0 2.309 9.219h2.779"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://music.apple.com/profile/colinlord/"
                    title="Apple Music"
                  >
                    <svg
                      id="svg-apple-music"
                      viewBox="36.471 38.394 437.749 439.6"
                    >
                      <path
                        d="m131.94 430.507c-94.767-68.476-116.341-201.133-48.188-296.3 68.149-95.165 200.227-116.803 294.996-48.327 94.767 68.473 116.342 201.13 48.193 296.3-68.154 95.165-200.228 116.803-295.001 48.327z"
                        stroke="#a6192e"
                        stroke-miterlimit="10"
                        stroke-width="15"
                      />
                      <path
                        d="m330.626 359.353c13.384-3.925 22.834-13.445 26.701-26.892l1.141-3.952.15-106.015c.111-79.393.004-106.509-.437-107.989-.464-1.569-1.2-2.885-2.163-3.925-1.417-.873-3.12-1.333-5.05-1.33-1.33 0-5.951.641-10.261 1.425-19.069 3.472-131.468 25.858-133.388 26.568-2.97 1.098-5.912 3.707-7.308 6.476l-1.183 2.346c0 0-.559 166.171-1.42 168.041-1.309 2.847-4.216 5.544-6.896 6.403-1.27.409-6.289 1.535-11.161 2.503-22.552 4.488-30.927 7.721-38.012 14.677-4.024 3.949-7.004 9.341-8.292 14.998-1.232 5.408-.82 13.5.935 18.421 1.839 5.146 4.799 9.541 8.669 12.999 3.518 2.711 7.671 4.729 12.34 5.927 10.323 2.643 29.65-.297 39.53-6.012 4.132-2.391 9.447-7.403 12.211-11.526 1.096-1.633 2.74-4.917 3.661-7.304 3.218-8.358 3.319-157.221 3.73-159.096.698-3.182 2.873-5.511 5.87-6.302 2.701-.709 110.93-22.107 113.977-22.534 2.68-.372 5.21.491 6.592 2.193.82.44 1.512 1.033 1.997 1.763.879 1.309.932 3.897 1.063 51.028.15 54.247.217 52.975-2.732 56.399-2.146 2.489-4.822 3.454-15.823 5.698-16.737 3.417-22.464 5.04-28.875 8.167-8.003 3.911-12.448 8.194-15.883 15.308-2.429 5.033-3.336 8.805-3.329 13.813.014 8.959 3.105 15.956 10.075 22.807.643.631 1.273 1.218 1.909 1.77 3.511 2.715 7.092 4.352 11.594 5.389 6.79 1.563 20.61.534 30.068-2.242z"
                        fill="#a6192e"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://untappd.com/user/colinlord" title="Untappd">
                    <svg id="svg-untappd" viewBox="0 0 1057.81 886.26">
                      <path
                        d="M934.2,1057.3c92.2,38.9,226.4-51.6,218.2-151L874.5,504.5l-95.7-52.4-214.3-230-3.9-16.9L546,201l-2.1-16.8c-38.8,3.7-64.5,21.5-80.8,55.9l15.3,7.7.5,14,14.4,9.6L636.5,550.6l19.6,105Z"
                        transform="translate(-94.95 -184.2)"
                      ></path>
                      <path
                        d="M95.1,906.7c-5.2,99.9,125.5,195.3,216.2,153.8L594.5,662.4l16.7-107.8L754.6,274.8l14.6-9.4-1-15.2,15.1-7.7c-16.7-35.2-42.2-53.3-80-56.9l-2,17-12.9,5.3-4.1,16.8-214,229.7-92,54.2Z"
                        transform="translate(-94.95 -184.2)"
                      ></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://foursquare.com/colinlord" title="Foursquare">
                    <svg id="svg-foursquare" viewBox="0 0 1792 1792">
                      <path d="M1256 434l37-194q5-23-9-40t-35-17h-712q-23 0-38.5 17t-15.5 37v1101q0 7 6 1l291-352q23-26 38-33.5t48-7.5h239q22 0 37-14.5t18-29.5q24-130 37-191 4-21-11.5-40t-36.5-19h-294q-29 0-48-19t-19-48v-42q0-29 19-47.5t48-18.5h346q18 0 35-13.5t20-29.5zm227-222q-15 73-53.5 266.5t-69.5 350-35 173.5q-6 22-9 32.5t-14 32.5-24.5 33-38.5 21-58 10h-271q-13 0-22 10-8 9-426 494-22 25-58.5 28.5t-48.5-5.5q-55-22-55-98v-1410q0-55 38-102.5t120-47.5h888q95 0 127 53t10 159zm0 0l-158 790q4-17 35-173.5t69.5-350 53.5-266.5z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://account.xbox.com/en-US/profile?gamertag=clordatl"
                    title="Xbox"
                  >
                    <svg
                      id="svg-xbox"
                      viewBox="0 0 16 16"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      stroke-linejoin="round"
                      stroke-miterlimit="1.414"
                    >
                      <path d="M2.734 14.024C4.14 15.256 5.984 16.002 8 16.002c2.017 0 3.86-.746 5.267-1.978C14.52 12.748 10.39 8.216 8 6.41c-2.388 1.806-6.52 6.338-5.266 7.614zm7.44-9.607c1.667 1.974 4.99 6.877 4.05 8.61C15.334 11.654 16 9.907 16 8.002c0-2.227-.91-4.242-2.38-5.692 0 0-.018-.015-.056-.028-.04-.015-.1-.03-.187-.03-.394 0-1.323.29-3.203 2.165zM2.436 2.282c-.038.013-.055.027-.057.028C.91 3.76 0 5.775 0 8.002c0 1.903.665 3.65 1.774 5.023C.84 11.288 4.16 6.39 5.827 4.417c-1.88-1.876-2.81-2.164-3.204-2.164-.087 0-.148.014-.187.03zM8 2.365S6.036 1.217 4.503 1.162c-.602-.022-.97.197-1.014.226 1.43-.96 2.95-1.39 4.5-1.39H8c1.556 0 3.07.43 4.51 1.39-.045-.03-.41-.248-1.013-.226C9.964 1.217 8 2.362 8 2.362v.004z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="https://social.lol/@colin" rel="me" title="Mastodon">
                    <svg
                      width="800px"
                      height="800px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      id="svg-mastdon"
                    >
                      <path d="M21.327 8.566c0-4.339-2.843-5.61-2.843-5.61-1.433-.658-3.894-.935-6.451-.956h-.063c-2.557.021-5.016.298-6.45.956 0 0-2.843 1.272-2.843 5.61 0 .993-.019 2.181.012 3.441.103 4.243.778 8.425 4.701 9.463 1.809.479 3.362.579 4.612.51 2.268-.126 3.541-.809 3.541-.809l-.075-1.646s-1.621.511-3.441.449c-1.804-.062-3.707-.194-3.999-2.409a4.523 4.523 0 0 1-.04-.621s1.77.433 4.014.536c1.372.063 2.658-.08 3.965-.236 2.506-.299 4.688-1.843 4.962-3.254.434-2.223.398-5.424.398-5.424zm-3.353 5.59h-2.081V9.057c0-1.075-.452-1.62-1.357-1.62-1 0-1.501.647-1.501 1.927v2.791h-2.069V9.364c0-1.28-.501-1.927-1.502-1.927-.905 0-1.357.546-1.357 1.62v5.099H6.026V8.903c0-1.074.273-1.927.823-2.558.566-.631 1.307-.955 2.228-.955 1.065 0 1.872.409 2.405 1.228l.518.869.519-.869c.533-.819 1.34-1.228 2.405-1.228.92 0 1.662.324 2.228.955.549.631.822 1.484.822 2.558v5.253z" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default IndexPage
